import React from "react";
import SharedHeader from "../../shared/header";

export default function Header() {
  return (
    <section  id="header">
      <SharedHeader
        align="right"
        titlesize="display-1"
        titlecolor="blue"
        title={<>Unsere Ergebnisse</>}
        text="Das kann in 3 Monaten entstehen, wenn wir gemeinsam anpacken:"
        textsize="body-3"
      />
    </section>
  );
}
