import React from "react";
import DefautLayout from "../layouts/default";
import * as ResultPage from "../components/screens/results";
import "../assets/sass/index.scss";

export default function Results() {
  return (
    <DefautLayout title="Ergebnisse">
      <div id="result">
        <ResultPage.Header />
        <ResultPage.Project />
      </div>
    </DefautLayout>
  );
}
